<template>
    <section class="app-main">
        <div class="container is-fluid is-marginless app-content">
            <div class="columns head is-vcentered">
                <div class="column is-offset-3 is-6 has-text-centered">
                <h3 class="title is-size-3 has-text-weight-normal">Coordinateurs</h3>
                </div>
                <div class="column is-3 is-hidden-mobile">
                <ol class="breadcrumb">
                    <li>
                    <a href="/#" class="is-active">Home</a>
                    </li>
                    <li>
                    <span class="active">Coordinateurs</span>
                    </li>
                </ol>
                </div>
            </div>
            <div class="animated">
                <div class="tile is-ancestor">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">Premier de coordo</p>
                            <p class="subtitle"><a href="http://louveletter.cooplalouve.fr/premierdecoordo" target="_blank" >Consultez l'archive des newsletters</a></p>
    
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">Formations coordinateurs</p>
                            <p class="subtitle"><a href="https://docs.google.com/forms/d/e/1FAIpQLSdV0DiSyGM9dj_CazoGo0_DFSmSukxKdubyvsmZZHW0xbLpLw/viewform" target="_blank">Inscription</a></p>
                        </article>
                    </div>
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <div class="content">
                                <p class="title">Besoins coordos volants</p>
                                
                                <p><a href="https://docs.google.com/spreadsheets/d/1p8dD7Pwp2rssXA03TEt_QfdxLNR1qd-I2vPFQkanqcA/edit?ts=5cd5911c#gid=0" target="_blank" class="button">Voir les besoins</a></p>
    
                            </div>
                        </article>
                    </div>
                </div>
            
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: "Coordos",
  data: function () {
    return {
      mesinfos: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}
</style>
